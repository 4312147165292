import Head from "next/head";
import { useRouter } from "next/router";
import { ReactElement, useEffect } from "react";
import type { NextPageWithLayout } from "./_app";

const IndexPage: NextPageWithLayout = () => {
  const router = useRouter();

  useEffect(() => {
    if (router.isReady === false) return;

    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      window.location.assign(process.env.NEXT_PUBLIC_MOBILE_WEB_URL ?? "")
      // router.replace(process.env.NEXT_PUBLIC_MOBILE_WEB_URL ?? "");
      return;
    }

    router.replace("/main");
  }, [router.isReady]);

  return <></>;
};

IndexPage.getLayout = function getLayout(page: ReactElement) {
  return page;
};

export default IndexPage;
